import { useMemo } from 'react'
import { useRecoilValue } from 'recoil'
import * as dayjs from 'dayjs'

import { useTranslation } from 'react-i18next'
import AudioControllerSlider from './AudioControllerSlider'
import AudioControllerButtonWrapper from './AudioControllerButtonWrapper'
import { selectedBoxesEstimatedDurationState } from '../../../recoil/texteditor/editor/selectors'

function AudioController() {
  const { t } = useTranslation('project')
  /** Recoils */
  const selectedBoxesEstimatedDuration = useRecoilValue(selectedBoxesEstimatedDurationState)

  /** Life Cycles */
  // useAivatarDidMount(() => {
  // }, []);

  /** Event hanlder */

  /** Display */

  const durationTime = useMemo(() => {
    if (selectedBoxesEstimatedDuration <= 0) return 0

    return dayjs.duration(selectedBoxesEstimatedDuration / 1000, 's').format('mm:ss')
  }, [selectedBoxesEstimatedDuration])

  return (
    <section className="shadow_1 media_controller_height flex h-[105px] w-full items-center justify-center rounded-full bg-[#FAFAFB] text-white">
      <div className="flex h-full items-center justify-center">
        <AudioControllerButtonWrapper />
        <AudioControllerSlider />
        <div className="ml-[10px] rounded-full bg-[#E8ECFB] px-[10px] py-[5px] text-[14px] text-[#9F9F9F]">
          {`${t('예상 음성 길이')} ${durationTime}`}
        </div>
      </div>
    </section>
  )
}

export default AudioController
