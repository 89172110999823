import { useState, useMemo, useCallback } from 'react'
import { NumericFormat } from 'react-number-format'
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import { useDebounce } from 'react-use'
import { useParams } from 'react-router-dom'
import {
  editorValueState,
  focusedBoxValueState,
  selectedBoxIdsState,
} from '../../../../../recoil/texteditor/editor/selectors'
import { SENTENCEBOX_CATEGORY, SentenceBoxValuesForFetch } from '../../../../../recoil/texteditor/sentenceBox/atoms'
import useAivatarDidMount from '../../../../../hooks/useAivatarDidMount'
import useEditorCallbacks from '../../../../../recoil/texteditor/editor/useEditorCallbacks'
import useDidMount from '../../../../../hooks/useDidMount'
import useUpdateAudio from '../../../../../services/audio/useUpdateAudio'
import useAudioControllerCallbacks from '../../../../../recoil/audiocontroller/useAudioControllerCallbacks'

const useOptionValueInput = (optionKey) => {
  const didMount = useDidMount()

  /** Recoilds */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const selectedBoxIds = useRecoilValue(selectedBoxIdsState)
  const focusedBoxId = useRecoilValue(editorValueState({ key: 'focusedBoxId' }))

  const setPlayed = useSetRecoilState(
    focusedBoxValueState({
      category: SENTENCEBOX_CATEGORY.AUDIO,
      key: 'played',
    }),
  )

  const audioId = useRecoilValue(
    focusedBoxValueState({
      category: SENTENCEBOX_CATEGORY.AUDIO,
      key: 'audioId',
    }),
  )

  const optionValue = useRecoilValue(
    focusedBoxValueState({
      category: SENTENCEBOX_CATEGORY.OPTION,
      key: optionKey,
    }),
  )
  const [floatValue, setFloatValue] = useState(optionValue)

  const { setOptionValues } = useEditorCallbacks()
  useAivatarDidMount(() => {
    setFloatValue(optionValue)
  }, [optionValue])

  /** Services */
  const { fetchAudioUpdate } = useUpdateAudio()

  /** Update Audio Effect */
  const [value, setValue] = useState(-1)

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { getAudioUpdateBody, setAudioById } = useAudioControllerCallbacks()
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { workspaceId, projectId } = useParams()
  const [sentenceBoxValues, setSentenceBoxValues] = useRecoilState(SentenceBoxValuesForFetch)

  const isInterval = useMemo(() => optionKey === 'space', [optionKey])
  const isValidOptionValue = useCallback(
    (targetValue) => {
      if (isInterval) {
        const validFloatNumbers = []
        // JS 내부 소수점 오류로 MS 로 계산해야함.
        for (let i = 0; i < 15; i += 1) {
          validFloatNumbers.push(100 + i * 100)
        }
        return validFloatNumbers.includes(targetValue * 1000)
      }
      const validFloatNumbers = []
      for (let i = 0; i < 11; i += 1) {
        validFloatNumbers.push(500 + i * 100)
      }
      return validFloatNumbers.includes(targetValue * 1000)
    },
    [isInterval],
  )

  const onChangeOptionValue = useCallback(
    (newValue) => {
      if (!isValidOptionValue(newValue)) return

      setFloatValue(newValue)
      setValue(newValue)
      setOptionValues({ optionKey, optionValue: newValue })
    },
    [isValidOptionValue, optionKey, setOptionValues],
  )

  // TODO 이거 상위로 빼고, 옵션 선택하는 코드(다중셀렉포함)랑 같이 커플링되야됨
  useDebounce(
    () => {
      // TODO 다중옵션 적용시 [focusedBoxId] -> selectedBoxId로 바꿔야됨
      const body = getAudioUpdateBody({ sentenceBoxIds: [focusedBoxId], projectId })
      if (!didMount) return
      if (!audioId) return
      if (value === -1) return
      if (!isValidOptionValue) return
      fetchAudioUpdate({ sentenceBoxIds: [focusedBoxId] })
      setSentenceBoxValues({ ...sentenceBoxValues, ...body.attributes[0] })
      setPlayed(0) // 재생바를 초기화해야 새로운음성이 생성되고 재생됨
    },
    500,
    [value],
  )

  /** Displays */

  const component = useMemo(
    () => (
      <div className="flex w-full justify-end">
        <div
          className={`flex h-[25px] w-[40px] ${
            isValidOptionValue ? 'border-[0.5px] border-[#9F9F9F]' : 'border-[0.5px] border-red-500'
          } rounded-md`}
        >
          <NumericFormat
            name="optionValue"
            className="w-full border-none bg-transparent px-[5px] text-[14px] outline-none"
            thousandSeparator={false}
            allowNegative={false}
            valueIsNumericString
            value={floatValue}
            onValueChange={(values, sourceInfo) => {
              if (sourceInfo.source === 'event') {
                onChangeOptionValue(values.floatValue)
              }
            }}
            onKeyDown={(e) => {
              if (e.code === 'Escape') {
                e.currentTarget.blur()
              }
            }}
          />
        </div>
      </div>
    ),
    [floatValue, isValidOptionValue, onChangeOptionValue],
  )

  return {
    optionValue,
    component,
    isValidOptionValue,
  }
}

export default useOptionValueInput
