import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'
import Dialog from '../../../Dialog'
import LogoImg from '../../../../assets/images/aivatar-logo.png'
import useDialog from '../../../../hooks/useDialog'
import PriceFormat from '../../../Text/PriceFormater'
import Section from './Section'
import Tr from './Tr'
import Td from './Td'
import Th from './Th'
import AivatarButton from '../../../buttons/AivatarButton'
import { getCardInfo } from '../../../../utils/number'

export default function PaymentDetailModal({ invoice }) {
  const dialog = useDialog()
  const { t } = useTranslation('invoice')

  return (
    <Dialog>
      <Section className="relative pt-[37px]">
        <img className="absolute right-0 top-0 h-[18px]" src={LogoImg} alt="logo" />
        <span className="text-gray-2 block text-[23px] font-bold">{t('청구서')}</span>
      </Section>
      <Section>
        <span className="text-gray-3 block text-[14px] font-medium">{t('인보이스 #')}</span>
        <span className="text-gray-21 mt-[12px] block text-[14px] font-medium">{invoice.paymentNumber}</span>
      </Section>
      <Section>
        <span className="text-gray-3 block text-[14px] font-medium">{t('결제 수단')}</span>
        <span className="text-gray-21 mt-[12px] block text-[14px] font-medium">
          {invoice.subscription?.paymentMethod
            ? getCardInfo(invoice.subscription?.paymentMethod.cardName, invoice.subscription?.paymentMethod.cardNumber)
            : null}
        </span>
        {/* <span className="mt-[2px] block text-[12px] font-medium text-gray-21">
          {invoice.subscription?.paymentMethod.cardNumber}
        </span> */}
      </Section>
      <Section className="bg-main-4 flex items-center rounded-[5px] p-[20px]">
        <div>
          <span className="text-gray-2 block">
            <strong translate="no">{invoice.subscription?.plan.name}</strong> ({t('월간 구독 플랜')})
          </span>
          <span className="text-gray-3 mt-[8px] block text-[12px]">
            {dayjs(invoice.subscription?.startedAt).format('YYYY-MM-DD')} -{' '}
            {dayjs(invoice.subscription?.endAt).format('YYYY-MM-DD')}
          </span>
        </div>
        <span className="text-gray-2 ml-auto text-[23px] font-bold">
          <PriceFormat amount={invoice.price} />
        </span>
      </Section>
      <Section>
        <div className="flex">
          {/* <span className="text-[12px] text-gray-3">
            인보이스 번호 : abcd2385d
          </span> */}
          <span className="text-gray-3 ml-auto text-[12px]">
            {t('결제일')} : {dayjs(invoice.paidAt).format('YYYY-MM-DD')}
          </span>
        </div>
        <table className="mt-[8px] w-full [&_tr]:border-0">
          <colgroup>
            <col />
            <col className="w-[110px]" />
            <col className="w-[90px]" />
            <col className="w-[100px]" />
          </colgroup>
          <thead>
            <Tr>
              <Th>{t('상품')}</Th>
              <Th>{t('단가')}</Th>
              <Th>{t('수량')}</Th>
              <Th>{t('금액')}</Th>
            </Tr>
          </thead>
          <tbody>
            <Tr>
              <Td>
                <span translate="no">{invoice.subscription?.plan.name}</span> {t('월간 구독 플랜')}
              </Td>
              <Td>
                <PriceFormat amount={invoice.subscription?.plan.amount} hideSuffix />
              </Td>
              <Td>1</Td>
              <Td>
                <PriceFormat amount={invoice.subscription?.plan.price} hideSuffix />
              </Td>
            </Tr>
            <Tr>
              <Td />
              <Td />
              <Td>{t('부가세')} (10%)</Td>
              <Td>
                {invoice.subscription ? (
                  <PriceFormat amount={invoice.subscription.plan.price * invoice.subscription.plan.vat} hideSuffix />
                ) : null}
              </Td>
            </Tr>
            <Tr>
              <Td />
              <Td />
              <Td className="text-main font-medium">{t('합계')}</Td>
              <Td className="text-main font-medium">
                <PriceFormat amount={invoice.subscription?.plan.amount} hideSuffix />
              </Td>
            </Tr>
          </tbody>
        </table>
      </Section>
      <Section className="flex !w-auto">
        <AivatarButton
          type="button"
          variant="g5"
          className="text-gray-2 h-[45px] w-auto min-w-[120px] shrink-0 px-[25px] font-bold"
          onClick={() => window.open(invoice.slipUrl)}
        >
          {t('매출전표 발행')}
        </AivatarButton>
        <AivatarButton
          type="button"
          variant="m1"
          className="ml-[15px] h-[45px] w-auto min-w-[120px] shrink-0 px-[25px] font-bold"
          onClick={dialog.close}
        >
          {t('닫기', { ns: 'modal' })}
        </AivatarButton>
      </Section>
    </Dialog>
  )
}
