import { Link } from 'react-router-dom'

function BottomTextLink({ title, link, dot }) {
  return (
    <>
      <Link className="link-bottom mx-3" to={link}>
        {title}
      </Link>
      {dot ? <span className="font-[900]"> {'\u00B7'} </span> : null}
    </>
  )
}

BottomTextLink.defaultProps = {
  dot: false,
}

export default BottomTextLink
