import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { v4 as uuid } from 'uuid'
import { useCallback, useMemo, useState } from 'react'
import { Checkbox, Grid2 } from '@mui/material'
import { BeatLoader } from 'react-spinners'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import moment from 'moment'
import { Trans, useTranslation } from 'react-i18next'
import Section from '../../Dashboard/Section'
import AivatarClient from '../../../services/AivatarClient'
import env from '../../../constants/env'
import PriceFormat from '../../Text/PriceFormater'
import { workspaceQueries } from '../../../services/workspace'
import { downgradeSubscribe, paymentsQueries, upgradeSubscribe } from '../../../services/payments'
import { getPlanColor } from '../../../utils/colors'
import useDialog from '../../../hooks/useDialog'
import PlanFeatures from '../PlanFeatures'
import AivatarButton from '../../buttons/AivatarButton'
import Receipt from './Receipt'
import useChangePaymentMethod from '../../../hooks/useChangePaymentMethod'
import { useCurrentWorkspacePaymentMethod } from '../../../hooks/queries'
import { getCardInfo } from '../../../utils/number'

export default function Checkout() {
  const queryClient = useQueryClient()
  const dialog = useDialog()

  const [searchParams] = useSearchParams()
  const { workspaceId } = useParams()
  const planId = searchParams.get('plan')
  const { data: paymentMethod } = useCurrentWorkspacePaymentMethod()
  const { data: checkout } = useQuery(paymentsQueries.checkout({ workspaceId, planId }))
  const { data: methods } = useQuery({
    ...paymentsQueries.methods,
    select: (data) => (data.length === 0 ? null : data),
  })
  const { mutate: mutateToUpgradeSubscribe, isLoading: isUpgrading } = useMutation({
    mutationFn: upgradeSubscribe,
  })
  const { mutate: mutateToDowngradeSubscribe, isLoading: isDowngrading } = useMutation({
    mutationFn: downgradeSubscribe,
  })
  const { changePaymentMethod, isChangingPaymentMethod } = useChangePaymentMethod()

  // const [plan, setPlan] = useState({});
  const [proceeding, setProceeding] = useState(false)
  const [termsChecked, setTermsChecked] = useState(false)

  const noticeUrl = `${env.API_HOST}/webhooks/payment`
  const navigate = useNavigate()
  const { t, i18n } = useTranslation('plan-checkout')

  const paidOnce = async (body) => {
    const { data } = await AivatarClient().post('/payments/paid_once', body)
    return data
  }

  const cb = async (resp) => {
    if (resp.success) {
      try {
        const body = {
          workspaceId,
          planId: checkout?.next.plan.id,
          result: {
            pg: 'portone',
            ...resp,
          },
        }

        // todo: 결제 중 로더
        setProceeding(true)
        await paidOnce(body)
        await queryClient.invalidateQueries({
          queryKey: workspaceQueries.detail(workspaceId).queryKey,
        })
        navigate(`/workspace/${workspaceId}/subscription/checkout/complete`, {
          replace: true,
        })
      } catch (e) {
        console.error(e)
        // eslint-disable-next-line no-alert
        window.alert(`결제 결과 전송 실패 - 관리자에게 문의바랍니다.: ${JSON.stringify(e)}`)
      } finally {
        setProceeding(false)
      }
    } else {
      console.log(resp)
      if (resp.error_msg.includes('PAY_PROCESS_CANCELED')) {
        // eslint-disable-next-line no-alert
        return window.alert('사용자가 결제를 취소하였습니다.')
      }
      // eslint-disable-next-line no-alert
      return window.alert(`결제 실패: ${resp.error_msg}`)
    }

    return undefined
  }

  const isUpgrade = useCallback(() => {
    const current = checkout?.current
    const next = checkout?.next

    if (!current || !next) return false

    if (current.status === 'PAUSE' || (current.plan.amount !== 0 && current.plan.amount < next.plan.amount)) {
      return true
    }
    return false
  }, [checkout])

  const isDowngrade = useCallback(() => {
    const current = checkout?.current
    const next = checkout?.next

    if (!current || !next) return false

    if (current.status !== 'PAUSE' && current.plan.amount !== 0 && current.plan.amount > next.plan.amount) {
      return true
    }
    return false
  }, [checkout])

  const onClickPayment = () => {
    if (isUpgrade()) {
      dialog.confirm({
        title: t('플랜을 업그레이드하시겠습니까?'),
        message: t('확인을 누르면 결제가 진행되고 플랜이 업그레이드됩니다.'),
        onClickConfirm: () => {
          setProceeding(true)
          mutateToUpgradeSubscribe(
            {
              workspaceId,
              paymentMethodId: methods?.[0].id,
              planId,
            },
            {
              onSuccess: async () => {
                await queryClient.invalidateQueries({
                  queryKey: workspaceQueries.detail(workspaceId).queryKey,
                })
                dialog.close()
                navigate(`/workspace/${workspaceId}/subscription/checkout/complete`, { replace: true })
              },
              onError: () => {
                dialog.alert({
                  message: '플랜 업그레이드에 실패했습니다. 관리자에게 문의바랍니다.',
                })
              },
              onSettled: () => {
                setProceeding(false)
              },
            },
          )
        },
      })

      return
    }

    if (isDowngrade()) {
      dialog.confirm({
        title: t('플랜을 다운그레이드하시겠습니까?'),
        message: t('확인을 누르면 다음 결제에 플랜이 다운그레이드됩니다.'),
        onClickConfirm: () => {
          setProceeding(true)
          mutateToDowngradeSubscribe(
            {
              workspaceId,
              paymentMethodId: methods?.[0].id,
              planId,
            },
            {
              onSuccess: async () => {
                await queryClient.invalidateQueries({
                  queryKey: workspaceQueries.detail(workspaceId).queryKey,
                })
                dialog.close()
                navigate(`/workspace/${workspaceId}/subscription/checkout/complete`, { replace: true })
              },
              onError: () => {
                dialog.alert({
                  message: '플랜 다운그레이드에 실패했습니다. 관리자에게 문의바랍니다.',
                })
              },
              onSettled: () => {
                setProceeding(false)
              },
            },
          )
        },
      })

      return
    }

    const { IMP } = window
    IMP.init(env.IMP)
    const cuid = uuid()
    const muid = uuid()

    const data = {
      pg: env.PG,
      pay_method: 'card',
      merchant_uid: muid,
      customer_uid: cuid,
      amount: checkout?.next.plan.amount,
      name: env.PAY_NAME,
      popup: true,
      language: i18n.language,
      notice_url: noticeUrl,
    }

    IMP.request_pay(data, cb)
  }

  // function onClickTest() {
  //   let r = {
  //     success: true,
  //     imp_uid: env.IMP,
  //     pay_method: 'card',
  //     merchant_uid: 'order-1099999', // 주문번호
  //     name: CONSTANTS.PAYMENT.NAME,
  //     paid_amount: priceObj.amount,
  //     currency: 'KRW',
  //     pg_provider: CONSTANTS.PAYMENT.PG,
  //     pg_type: 'payment',
  //     pg_tid: '',
  //     apply_num: '',
  //     buyer_name: '',
  //     buyer_email: '',
  //     buyer_tel: '',
  //     buyer_addr: '',
  //     buyer_postcode: '',
  //     custom_data: null,
  //     status: 'paid',
  //     paid_at: 1699400690,
  //     receipt_url: '',
  //     card_name: '신한카드',
  //     bank_name: null,
  //     card_quota: 0,
  //     card_number: '54287966****705*',
  //     customer_uid: 'cuid-102938',
  //   }
  //   cb(r).then(console.log)
  // }

  const nextPaymentDate = useMemo(() => {
    if (isUpgrade()) {
      return moment(checkout?.next.endAt).add(1, 'days').format('YYYY-MM-DD')
    }

    if (isDowngrade()) {
      return moment(checkout?.current.endAt).add(1, 'days').format('YYYY-MM-DD')
    }

    return moment(checkout?.next.endAt).add(1, 'days').format('YYYY-MM-DD')
  }, [checkout, isDowngrade, isUpgrade])

  return (
    <Section className="mt-[40px]" title={t('결제 내역')}>
      {isChangingPaymentMethod ||
        (proceeding && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
              position: 'fixed',
              zIndex: 9999,
              left: 0,
              top: 0,
              width: '100%',
              height: '100%',
              background: 'rgba(0, 0, 0, 0.5)',
            }}
          >
            <BeatLoader color="#3498db" loading={proceeding} size={24} />
            <p style={{ color: '#fff', marginTop: '20px' }}>Requesting...</p>
          </div>
        ))}

      <div className="mt-[35px]">
        <Grid2 container spacing="45px">
          <Grid2 item size={6}>
            <div className="bg-gray-6 rounded-[5px] p-[5px]">
              <table className="[&_td:first-child]:text-gray-3 w-full text-[17px] [&_td]:p-[15px] [&_td]:font-[700]">
                <colgroup>
                  <col className="w-[150px]" />
                  <col />
                  <col className="w-[150px]" />
                </colgroup>
                <tr>
                  <td>{t('기존 플랜')}</td>
                  <td>
                    <span
                      translate="no"
                      className="inline-block text-[var(--color)]"
                      style={{
                        '--color': getPlanColor(checkout?.current.plan.name),
                      }}
                    >
                      {checkout?.current.plan.name}
                    </span>
                  </td>
                  {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                  <td>
                    <PriceFormat amount={checkout?.current.price} />
                  </td>
                </tr>
                <tr>
                  <td>{t('신규 플랜')}</td>
                  <td>
                    <span
                      translate="no"
                      className="inline-block text-[var(--color)]"
                      style={{
                        '--color': getPlanColor(checkout?.next.plan.name),
                      }}
                    >
                      {checkout?.next.plan.name}
                    </span>
                    <br />
                  </td>
                  {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                  <td>
                    <PriceFormat amount={checkout?.next.price} />
                  </td>
                </tr>
              </table>
            </div>

            <div className="mt-[25px]">
              <div className="mb-[15px] flex items-end justify-between">
                <div className="pl-[20px] text-[17px] font-[700]">{t('결제 수단')}</div>
                {paymentMethod ? (
                  <AivatarButton type="button" variant="m4" onClick={() => changePaymentMethod()}>
                    {t('변경')}
                  </AivatarButton>
                ) : null}
              </div>
              <div className="bg-gray-6 rounded-[5px] px-[30px] py-[25px] leading-[1.93]">
                {paymentMethod ? (
                  <div
                    key={`${paymentMethod.cardName}-${paymentMethod.cardNumber}`}
                    className="text-[16px] font-[700] text-[#5e5e5e]"
                  >
                    {paymentMethod ? getCardInfo(paymentMethod.cardName, paymentMethod.cardNumber) : null}
                  </div>
                ) : (
                  <span className="text-gray-3 font-[700]">{t('아직 등록된 결제 수단이 없습니다.')}</span>
                )}
              </div>
            </div>

            <div className="mt-[35px]">
              <div className="mb-[15px] pl-[20px] text-[17px] font-[700]">
                {t('{{plan_name}} 플랜에는 아래 사항이 포함됩니다.', {
                  plan_name: checkout?.next.plan.name,
                })}
              </div>
              <div className="bg-gray-6 rounded-[5px] px-[30px] py-[25px] leading-[1.93]">
                {checkout?.next.plan.name ? <PlanFeatures planName={checkout?.next.plan.name} /> : null}
              </div>
            </div>
          </Grid2>
          <Grid2 item size={6}>
            {checkout ? <Receipt className="mt-[30px]" plan={checkout?.next.plan} /> : null}
            <span className="inline-block pl-[7px] text-[12px] font-[400]">
              <span>{t('다음 결제일')}: </span>
              <span>{nextPaymentDate}</span>
            </span>
            {isDowngrade() ? (
              <p className="text-point-3 pl-[7px] text-[14px]">지금 결제되지 않고, 다음 결제일에 결제됩니다.</p>
            ) : null}
            <div className="mt-[22px] flex">
              <Checkbox
                id="terms"
                className="flex-1"
                checked={termsChecked}
                onChange={(e) => setTermsChecked(e.target.checked)}
              />
              <label htmlFor="terms" className="text-[14px] text-[#5e5e5e]">
                <Trans t={t}>
                  자동 정기 결제에 대한 내용을 포함하여,{' '}
                  <a href="https://www.aivatar.ai/termsandconditions" target="_blank" rel="noreferrer">
                    서비스 이용약관
                  </a>{' '}
                  및{' '}
                  <a href="https://www.aivatar.ai/privacypolicy" target="_blank" rel="noreferrer">
                    개인정보처리방침
                  </a>
                  을 모두 확인하였으며 동의합니다.
                </Trans>
              </label>
            </div>

            <AivatarButton
              disabled={isUpgrading || isDowngrading || !termsChecked}
              className="btn-main mx-auto mt-[34px] h-[45px] px-[32px] text-[17px]"
              onClick={onClickPayment}
            >
              {isDowngrade() ? t('변경하기') : t('결제하기')}
            </AivatarButton>
          </Grid2>
        </Grid2>
      </div>
    </Section>
  )
}
