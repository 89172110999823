import { useCallback, useMemo, useState } from 'react'
import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'
import Table from '../../Table/index'
import useDialog from '../../../hooks/useDialog'
import PaymentDetailModal from './PaymentDetailModal'
import { useCurrentWorkspaceInvoiceListQuery } from '../../../hooks/queries'
import PriceFormat from '../../Text/PriceFormater'

export default function PaymentHistorySection({ className }) {
  const dialog = useDialog()
  const [page, setPage] = useState(0)
  const pageSize = 10
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [sort, setSort] = useState([{ key: 'paidAt', order: 'desc' }])
  const { data: invoices } = useCurrentWorkspaceInvoiceListQuery({
    page,
    size: pageSize,
    sort: sort[0] ? `${sort[0].key},${sort[0].order}` : undefined,
  })
  const { t } = useTranslation('subscription')
  // const {data:invoices} = useQuery

  const rows = invoices?.content ?? []
  const total = invoices?.totalElements ?? 0

  // useEffect(() => {
  //   // todo: get invoices
  //   AivatarClient()
  //     .get(`/workspaces/${workspaceId}/invoices`, {
  //       params: {
  //         page,
  //         size: pageSize,
  //         sort: sort[0] ? `${sort[0].key},${sort[0].order}` : undefined,
  //       },
  //     })
  //     .then((res) => {
  //       setInvoices(res.data)
  //     })
  // }, [page, sort])

  const showPaymentDetail = useCallback(
    (row) => {
      dialog.open(<PaymentDetailModal invoice={row} />)
    },
    [dialog],
  )

  const columns = useMemo(
    () => [
      {
        key: 'paidAt',
        name: t('결제일'),
        className: 'text-left w-[120px]',
        render: (row) => dayjs(row.paidAt).format('YYYY/MM/DD'),
      },
      {
        key: 'planName',
        name: t('상품'),
        render: (row) => (
          <span translate="no" className="block text-center">
            {row.subscription?.plan.name} {t('월간 구독 플랜')}
          </span>
        ),
      },
      {
        key: 'price',
        name: t('금액'),
        className: 'text-left w-[120px]',
        render: (row) => <PriceFormat amount={row.price} />,
      },
      {
        key: 'detail',
        name: t('청구서'),
        className: 'text-center w-[120px]',
        render: (row) => (
          <button
            className="text-hyperlink hover:underline"
            onClick={() => {
              showPaymentDetail(row)
            }}
          >
            {t('보기')}
          </button>
        ),
      },
    ],
    [showPaymentDetail, t],
  )

  return (
    <section className={className}>
      <span className="text-gray-2 mb-[-60px] block text-[17px] font-bold">{t('결제 이력')}</span>
      <Table
        className={`mt-[-20px] ${className}`}
        columns={columns}
        rows={rows}
        usePagination
        sort={sort}
        page={page}
        onChangePage={setPage}
        pageSize={pageSize}
        total={total}
      />
    </section>
  )
}
