import { useRef, useState, useEffect } from 'react'
import ReactPlayer from 'react-player'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import useAivatarDidMount from '../../../../hooks/useAivatarDidMount'

import { editorAudioValueState } from '../../../../recoil/texteditor/editor/selectors'
import useAudioControllerCallbacks from '../../../../recoil/audiocontroller/useAudioControllerCallbacks'
import { projectValueState } from '../../../../recoil/project/selectors'
import createOrGetSilentAudioUrl from '../../../../utils/createOrGetSilentAudioUrl'

function FirstIntervalAudioManager() {
  const player = useRef(null)
  const [intervalUrl, setIntervalUrl] = useState(null)

  /** Recoils */
  const firstInterval = useRecoilValue(projectValueState({ key: 'firstInterval' }))

  const intervalPlaying = useRecoilValue(editorAudioValueState({ key: 'playing' }))
  const setIntervalPlayed = useSetRecoilState(editorAudioValueState({ key: 'played' }))

  const { nextPlayInFirstInterval } = useAudioControllerCallbacks()

  /** Life Cycle */
  useAivatarDidMount(() => {})

  /** Methods */

  /** Event Handler */

  /** Event Subscribers */
  const onEnded = () => {
    console.log('onEnded - First Interval', nextPlayInFirstInterval)
    nextPlayInFirstInterval()
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const onProgress = ({ played, playedSeconds, loadedSeconds }) => {
    setIntervalPlayed(played)
  }

  useEffect(() => {
    if (firstInterval > 10 || firstInterval <= 0) {
      setIntervalUrl(null)
    } else {
      createOrGetSilentAudioUrl(firstInterval).then((url) => setIntervalUrl(url))
    }
  }, [firstInterval])

  return (
    <ReactPlayer
      id="FirstIntervalPlayer"
      ref={player}
      playing={intervalPlaying}
      className="react-player"
      style={{
        display: 'none',
      }}
      url={intervalUrl}
      autoPlay
      controls
      playsinline
      progressInterval={100}
      width={0}
      height={0}
      config={{
        file: {
          forceAudio: true,
          attributes: {
            // autoPlay: false,
            // controlsList: "nodownload",
            crossOrigin: 'anonymous',
            onContextMenu: (e) => e.preventDefault(),
          },
        },
      }}
      // onReady={onReady}
      // onSeek={onSeek}
      onProgress={onProgress}
      onEnded={onEnded}
      // onError={onError}
      // onDuration={onDuration}
      // onBuffer={onBuffer}
    />
  )
}

export default FirstIntervalAudioManager
