import { useRef, useState, useMemo, useEffect } from 'react'
import { motion } from 'framer-motion'
import ReactPlayer from 'react-player'
import { useRecoilValue, useSetRecoilState } from 'recoil'

import IntervalImage from '../../../../../assets/images/texteditor_interval-background.png'

import NotSelectedIntervalImage from '../../../../../assets/images/texteditor_interval-background-notselected.png'
import SentenceBoxConnector from '../../../../../providers/SentenceBoxConnector'

import {
  isValidSentenceBoxState,
  sentenceBoxAudioValueState,
  sentenceBoxOptionValueState,
  sentenceBoxValueState,
} from '../../../../../recoil/texteditor/sentenceBox/selectors'
import useAudioControllerCallbacks from '../../../../../recoil/audiocontroller/useAudioControllerCallbacks'
import { SENTENCEBOX_CATEGORY } from '../../../../../recoil/texteditor/sentenceBox/atoms'
import { focusedBoxValueState } from '../../../../../recoil/texteditor/editor/selectors'
import createOrGetSilentAudioUrl from '../../../../../utils/createOrGetSilentAudioUrl'

function SentenceInterval() {
  const [intervalUrl, setIntervalUrl] = useState(null)

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { sentenceBoxId, sentenceBoxIndex } = SentenceBoxConnector.useContainer()
  const player = useRef(null)
  const [isEnded, setIsEnded] = useState(false)

  /** Recoils */
  // const { isSelected } = sentenceBox;

  const isSelected = useRecoilValue(sentenceBoxValueState({ id: sentenceBoxId, key: 'isSelected' }))

  const focusedBoxId = useRecoilValue(focusedBoxValueState({ key: 'id', category: SENTENCEBOX_CATEGORY.GENERAL }))

  const space = useRecoilValue(sentenceBoxOptionValueState({ id: sentenceBoxId, key: 'space' }))
  const intervalPlaying = useRecoilValue(sentenceBoxAudioValueState({ id: sentenceBoxId, key: 'intervalPlaying' }))
  const setIntervalPlayed = useSetRecoilState(sentenceBoxAudioValueState({ id: sentenceBoxId, key: 'intervalPlayed' }))

  const isValidSentenceBox = useRecoilValue(isValidSentenceBoxState(sentenceBoxId))

  const { updateNextFocused } = useAudioControllerCallbacks()

  /** Event Handlers */

  const onEnded = () => {
    console.log(`on Interval Ended - ${sentenceBoxId}`)
    updateNextFocused(sentenceBoxId)
    setIsEnded(true)
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const onProgress = ({ played, playedSeconds, loadedSeconds }) => {
    if (isEnded) return
    setIntervalPlayed(played)
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const onReady = (ready) => {
    setIsEnded(false)
  }

  /** Memos */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const isOpen = useMemo(() => isSelected, [isSelected])

  const isFocusedBox = useMemo(() => focusedBoxId === sentenceBoxId, [focusedBoxId, sentenceBoxId])

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const intervalImageSrc = useMemo(
    () => (!isValidSentenceBox ? NotSelectedIntervalImage : isFocusedBox ? IntervalImage : NotSelectedIntervalImage),
    [isFocusedBox, isValidSentenceBox],
  )

  useEffect(() => {
    if (space > 1.5 || space < 0.1) {
      setIntervalUrl(null)
    } else {
      createOrGetSilentAudioUrl(space).then((url) => setIntervalUrl(url))
    }
  }, [space])

  return (
    <motion.span
      className={`inline-block align-middle ${intervalPlaying ? 'animate-pulse bg-blue-100' : ''}`}
      // animate={{
      //   width: isOpen ? 15 : 0
      // }}
    >
      <ReactPlayer
        key={sentenceBoxId}
        id={`IntervalPlayer-${sentenceBoxId}`}
        ref={player}
        playing={intervalPlaying}
        className="react-player"
        style={{
          display: 'none',
        }}
        url={intervalUrl}
        autoPlay
        controls
        playsinline
        progressInterval={100}
        width={0}
        height={0}
        config={{
          file: {
            attributes: {
              // autoPlay: false,
              // controlsList: "nodownload",
              crossOrigin: 'anonymous',
              onContextMenu: (e) => e.preventDefault(),
            },
          },
        }}
        onReady={onReady}
        // onSeek={onSeek}
        onProgress={onProgress}
        onEnded={onEnded}
        // onError={onError}
        // onDuration={onDuration}
        // onBuffer={onBuffer}
      />
      {/* <motion.span
        className="relative w-full h-full inline-block align-middle text-cetner cursor-pointer"
        animate={{
          opacity: isOpen ? 1 : 0
        }}
      >
        {isFocusedBox && (
          <div id="tooltip" className="bottom">
            <div className="tooltip-arrow" />
            <div className="tooltip-label">{space}</div>
          </div>
        )}
        <div className="w-[15px] h-[30px] absolute top-[-18px]  inline-flex  justify-center items-center overflow-hidden">
          <div className="relative  w-full h-full flex justify-center items-center">
            <img
              className="absolute w-full h-full "
              src={intervalImageSrc}
              alt="space"
            />
          </div>
        </div>
      </motion.span> */}
    </motion.span>
  )
}

export default SentenceInterval
