import { useContext, useMemo } from 'react'
import { useRecoilValue } from 'recoil'
import { useTranslation } from 'react-i18next'
import { twMerge } from 'tailwind-merge'
import useAivatarDidMount from '../../../../hooks/useAivatarDidMount'
import useAudioControllerCallbacks from '../../../../recoil/audiocontroller/useAudioControllerCallbacks'

import { ReactComponent as PlayIcon } from '../../../../assets/images/player-play.svg'
import { ReactComponent as PauseIcon } from '../../../../assets/images/player-pause.svg'
import PlayerBackIcon from '../../../../assets/images/player-back.png'
import PlayerNextIcon from '../../../../assets/images/player-next.png'
import { editorValidationState, focusedBoxValueState } from '../../../../recoil/texteditor/editor/selectors'
import { SENTENCEBOX_CATEGORY } from '../../../../recoil/texteditor/sentenceBox/atoms'
import useUpdateProject from '../../../../services/project/useUpdateProject'
import {
  currentPlayingTypeState,
  CURRENT_PLAYING_TYPE,
  isPlayingState,
} from '../../../../recoil/audiocontroller/selectors'

import { setSentenceBoxAudioManager } from '../../../../recoil/audiocontroller/audioControllerHelpers'
import useFirstAudioCreationHandler from '../hooks/useFirstAudioCreationHandler'
import AKLoading from '../../../../components/Loading/AKLoading'
import {
  isLoadingAudioState,
  isValidSentenceBoxState,
  sentenceBoxValueState,
} from '../../../../recoil/texteditor/sentenceBox/selectors'
import { isLoadingProjectState } from '../../../../recoil/project/selectors'
import { HotkeysContext } from '../../../../providers/AivatarHotKeysRoot'
import usePlaceholderSentenceText from '../../../../hooks/usePlaceholderSentenceText'
import { sentenceBoxIdsState } from '../../../../recoil/texteditor/editor/atoms'

function AudioControllerButtonWrapper() {
  const { t } = useTranslation('project')
  const { playButtonRef, prevButtonRef, nextButtonRef } = useContext(HotkeysContext)
  const { placeholderSentenceText } = usePlaceholderSentenceText()

  /** Recoils */
  const editorValidation = useRecoilValue(editorValidationState)
  const id = useRecoilValue(focusedBoxValueState({ category: SENTENCEBOX_CATEGORY.AUDIO, key: 'id' }))
  const sentenceBoxIds = useRecoilValue(sentenceBoxIdsState)
  const firstSentenceBoxText = useRecoilValue(sentenceBoxValueState({ id: sentenceBoxIds[0], key: 'text' }))
  const played = useRecoilValue(
    focusedBoxValueState({
      category: SENTENCEBOX_CATEGORY.AUDIO,
      key: 'played',
    }),
  )
  const duration = useRecoilValue(
    focusedBoxValueState({
      category: SENTENCEBOX_CATEGORY.AUDIO,
      key: 'duration',
    }),
  )

  const currentPlayingType = useRecoilValue(currentPlayingTypeState)

  const isPlaying = useRecoilValue(isPlayingState)

  const isLoadingProject = useRecoilValue(isLoadingProjectState)
  const isLoadingAudio = useRecoilValue(isLoadingAudioState({ id }))

  const isValidSentenceBox = useRecoilValue(isValidSentenceBoxState(id))

  const {
    updateStartFocused,
    updateNextFocused,
    updatePrevFocused,
    nextPlayInFirstInterval,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    checkIsValidAudioPreplay,
  } = useAudioControllerCallbacks()

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { actionType, setActionType, FIRST_CREATION_ACTION } = useFirstAudioCreationHandler()
  /** Services */
  const { fetchProject } = useUpdateProject()

  /** Values */
  const currentPlayingSeconds = useMemo(() => played * duration, [played, duration])

  const isMore2Seconds = useMemo(() => currentPlayingSeconds > 2, [currentPlayingSeconds])

  /** Displays */
  const displayPlayIcon = useMemo(
    () =>
      isPlaying ? (
        <PauseIcon className="group-hover:[&_path]:fill-[#004fff]" />
      ) : (
        <PlayIcon className="group-hover:[&_path]:fill-[#004fff]" />
      ),
    [isPlaying],
  )

  const isLoadingPlay = useMemo(() => isLoadingProject || isLoadingAudio, [isLoadingProject, isLoadingAudio])

  /** Validations */

  const isCurrentPlayingNew = useMemo(() => currentPlayingType === CURRENT_PLAYING_TYPE.NEW, [currentPlayingType])

  const isValidPlayAction = useMemo(
    () =>
      !isLoadingPlay &&
      isValidSentenceBox &&
      (currentPlayingType === CURRENT_PLAYING_TYPE.SENTENCE || currentPlayingType === CURRENT_PLAYING_TYPE.NEW),
    [isLoadingPlay, currentPlayingType, isValidSentenceBox],
  )

  const isValidMoveTract = useMemo(() => isValidPlayAction && isPlaying, [isValidPlayAction, isPlaying])

  const isInitializedSentenceBox = sentenceBoxIds.length === 1 && firstSentenceBoxText === placeholderSentenceText
  const disabledPlayButton = !isValidPlayAction || isLoadingPlay || isInitializedSentenceBox || !editorValidation.valid

  /** Event Handler */

  const prePlayProcess = async () => {
    // 1. Validation
    // const isValid = checkIsValidAudioPreplay()
    // if (!isValid) return
    // 2. Update Focused
    updateStartFocused()

    // 3 Update Project
    try {
      await fetchProject()
      setActionType(FIRST_CREATION_ACTION.CREATION)
    } catch (error) {
      /* empty */
    }
  }

  const handlePlayer = async () => {
    if (disabledPlayButton) return
    try {
      if (isPlaying) {
        // 일시정지
        setActionType(FIRST_CREATION_ACTION.PLAY)
      } else if (isCurrentPlayingNew) {
        // 재생 시도
        await prePlayProcess()
      } else {
        // 중간부터 다시 재생
        setActionType(FIRST_CREATION_ACTION.PLAY)
      }
    } catch (error) {
      console.error(error)
    }
  }

  const onClickPrev = () => {
    // 2초
    if (isMore2Seconds) {
      setSentenceBoxAudioManager({ id })
      return
    }
    updatePrevFocused(id)
  }

  const onClickNext = () => {
    if (currentPlayingType === CURRENT_PLAYING_TYPE.FIRST_INTERVAL) {
      nextPlayInFirstInterval()
      return
    }
    updateNextFocused(id)
  }
  /** Life Cycle */
  useAivatarDidMount(async () => {})

  return (
    <div className="justify-center` relative mr-[30px] flex items-center">
      <button
        type="button"
        ref={prevButtonRef}
        className={`${
          !isValidMoveTract && 'pointer-events-none opacity-70'
        } shadow_2 mr-[10px] flex h-[30px] w-[30px] cursor-pointer items-center justify-center rounded-full bg-white transition-all hover:bg-[#F5F7FE]`}
        onClick={onClickPrev}
        aria-label={t('이전 문장 재생')}
      >
        <div className="flex w-[14px]">
          <img className="flex w-full" src={PlayerBackIcon} alt="" />
        </div>
      </button>
      <button
        disabled={!isValidPlayAction || !editorValidation.valid}
        type="button"
        ref={playButtonRef}
        className={twMerge(
          `shadow_2 hover:bg-main-3 group relative mr-[10px] flex h-[50px] w-[50px] cursor-pointer items-center justify-center rounded-full bg-white transition-all`,
          disabledPlayButton ? 'pointer-events-none opacity-70' : null,
        )}
        onClick={handlePlayer}
        aria-label={isPlaying ? t('일시정지') : t('재생')}
      >
        {displayPlayIcon}
        {isLoadingPlay && <AKLoading size="50px" thickness="6px" />}
      </button>
      <button
        type="button"
        ref={nextButtonRef}
        className={`${
          !isValidMoveTract && 'pointer-events-none opacity-70'
        } shadow_2 flex h-[30px] w-[30px] cursor-pointer items-center justify-center rounded-full bg-white transition-all hover:bg-[#F5F7FE]`}
        onClick={onClickNext}
        aria-label={t('다음 문장 재생')}
      >
        <div className="flex w-[14px]">
          <img className="flex w-full" src={PlayerNextIcon} alt="" />
        </div>
      </button>
    </div>
  )
}

export default AudioControllerButtonWrapper
